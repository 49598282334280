<template>
	<div>
		<div class="row content-header">
			<div class="content-header-left mb-2 col-md-9 col-12">
				<div class="row breadcrumbs-top">
					<div class="col-12">
						<h2 class="content-header-title float-left pr-1 mb-0">
							<feather-icon icon="ArrowLeftIcon" size="24" class="mr-2" @click="goBack" />
							Egg Weight Entry
						</h2>
						<div class="breadcrumb-wrapper">
							<ol class="breadcrumb">
								<li class="breadcrumb-item active">
									<span aria-current="location"> Add </span>
								</li>
							</ol>
						</div>
					</div>
				</div>
			</div>
		</div>
		<validation-observer ref="simpleRules">
			<b-form action="apps-purchase" method="post" @submit="addEggWeight">
				<b-card>
					<!-- <div class="d-flex justify-content-end mb-0 mt-2 mr-2">
						<b-button variant="primary"> Total Alive Birds : {{ this.total_chick ? total_chick : 0 }} </b-button>
					</div> -->
					<b-row>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Date <span class="text-danger">*</span> </template>
								<validation-provider #default="{ errors }" name="Entry Date" :rules="{ required: true }">
									<b-form-datepicker
										v-model="FormData.entry_date"
										menu-class="w-100"
										calendar-width="100%"
										class="mb-1"
									/>
									<small class="text-danger">{{ errors[0] }}</small>
								</validation-provider>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> No. of Egg <span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider #default="{ errors }" name="No. of Egg" rules="required|regex:^([0-9]+)$">
										<b-form-input
											v-model="FormData.egg_quantity"
											placeholder="No. of Egg"
											type="text"
											name="egg_quantity"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Tray Weight (g)<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Tray Weight"
										:rules="{
											required: true,
											regex: RegExp(/^\d+(\.\d{1,2})?$/),
										}"
									>
										<b-form-input
											v-model="FormData.tray_weight"
											placeholder="Tray Weight (g)"
											type="text"
											name="tray_weight"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Average Weight (g)<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Average Weight"
										:rules="{
											required: true,
											regex: RegExp(/^\d+(\.\d{1,2})?$/),
										}"
									>
										<b-form-input
											v-model="FormData.avg_weight"
											placeholder="Average Weight (g)"
											type="text"
											name="avg_weight"
										/>
										<small class="text-danger">{{ errors[0] }}</small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>
						<b-col md="6">
							<b-form-group>
								<template v-slot:label> Total Weight (g)<span class="text-danger">*</span> </template>
								<div class="form-label-group">
									<validation-provider
										#default="{ errors }"
										name="Total Weight"
										:rules="{
											required: true,
											regex: RegExp(/^\d+(\.\d{1,2})?$/),
										}"
									>
										<b-form-input
											v-model="FormData.total_weight"
											placeholder="Total Weight (g)"
											type="text"
											name="total_weight"
										/>
										<small class="text-danger">{{ errors[0] }} </small>
									</validation-provider>
								</div>
							</b-form-group>
						</b-col>

						<!-- submit and reset -->
						<b-col md="12">
							<b-button type="submit" variant="primary" value="Submit" class="mr-1 mb-3"> Submit </b-button>
						</b-col>
					</b-row>
				</b-card>
			</b-form>
		</validation-observer>
	</div>
</template>

<script>
import Logo from "@core/layouts/components/Logo.vue";

import * as Vue from "vue";
import axios from "axios";

import { BASE_URL } from "@core/common/constants";
import moment from "moment";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import flatPickr from "vue-flatpickr-component";
import axiosIns from "@/libs/axios";
import { ValidationProvider, ValidationObserver } from "vee-validate";
import { required, email } from "@validations";
export default {
	components: {
		flatPickr,
		vSelect,
		Logo,
	},

	data() {
		return {
			required,
			selected: null,
			FormData: {
				avg_weight: null,
				egg_quantity: null,
				total_weight: null,
				tray_weight: null,
				created_at: null,
			},
		};
	},
	created: function () {
		this.flockId = this.$route.params.flockId;
		this.farmId = this.$route.params.farmId;
		this.shedId = this.$route.params.shedId;
	},
	methods: {
		goBack() {
			this.$router.push(`/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`);
		},
		addEggWeight(e) {
			e.preventDefault();
			this.$refs.simpleRules.validate().then((success) => {
				if (success) {
					var data = new FormData();
					data.append("avg_weight", this.FormData.avg_weight);
					data.append("egg_quantity", this.FormData.egg_quantity);
					data.append("total_weight", this.FormData.total_weight);
					data.append("tray_weight", this.FormData.tray_weight);
					data.append("entry_date", this.FormData.entry_date);
					axiosIns
						.post(`web/farm/${this.farmId}/shed/${this.shedId}/flock/${this.flockId}/egg-weight-entry`, data)
						.then((res) => {
							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "success",
									text: `Data Successfully Added `,
								},
							});
							this.$router.push({
								path: `/apps/manage-farm/${this.farmId}/shed/${this.shedId}/data-entry/${this.flockId}`,
							});
						})
						.catch((error) => {
							const data = error.response.data.errors;
							data, "data";
							let arr = Object.values(data).flat().toString(",");

							this.$toast({
								component: ToastificationContent,
								position: "top-right",
								props: {
									icon: "CoffeeIcon",
									variant: "danger",
									text: arr,
								},
							});
						});
				}
			});
		},
	},
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
.invoice-add-wrapper {
	.add-new-client-header {
		padding: $options-padding-y $options-padding-x;
		color: $success;

		&:hover {
			background-color: rgba($success, 0.12);
		}
	}
}
</style>

<style lang="scss" scoped>
@import "~@core/scss/base/pages/app-invoice.scss";
@import "~@core/scss/base/components/variables-dark";
